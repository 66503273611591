import React from "react"
import Box from "@material-ui/core/Box"
import Hidden from "@material-ui/core/Hidden"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

import Layout from "../components/layout"
import Seo from "../components/seo"

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(25),
    paddingBottom: theme.spacing(12.5),
  },
  title: {},
  container: {},
  payments: {
    marginTop: theme.spacing(4),
  },
  mobilePrices: {
    overflowX : "auto",
    "& table": {
      marginTop: theme.spacing(6.25),
      width: "100%",
      "& td": {
        color: "#B136FF",
        border: "3px solid #B136FF",
        padding: theme.spacing(1),
        width: "50%",
      },
      "& td:first-child": {
        fontWeight: "bold",
        color: "#fff",
      },
    },
  },
  desktopPrices: {
    marginTop: theme.spacing(4),
    overflowX : "auto",
    "& table": {
      width: "100%",
      "& td": {
        border: "3px solid #B136FF",
        padding: theme.spacing(1),
        textAlign: "center",
      },
      "& tbody": {
        "& tr": {
          color: "#B136FF",
        },
        "& tr:first-child": {
          fontWeight: "bold",
          color: theme.palette.common.white,
        },
      },
    },
  },
}))

const PricePage = ({ data }) => {
  const classes = useStyles()
  const { datoCmsPaymentsPage } = data

  return (
    <Layout bgColor="#ffdd00">
      <Seo title="Cennik" />
      <Box className={classes.root}>
        <Container maxWidth="md" className={classes.container}>
          <Typography gutterBottom variant="h1" className={classes.title}>
            {datoCmsPaymentsPage.title}
          </Typography>
          <Typography gutterBottom variant="body1" className={classes.payments}>
            {datoCmsPaymentsPage.payments}
          </Typography>
          
          <Hidden smUp>
            <Typography
              component="div"
              variant="body1"
              className={classes.mobilePrices}
              dangerouslySetInnerHTML={{
                __html: datoCmsPaymentsPage.mobilePrices,
              }}
            />
          </Hidden>
          
          <Hidden xsDown>
            <Typography
              component="div"
              variant="body1"
              className={classes.desktopPrices}
              dangerouslySetInnerHTML={{
                __html: datoCmsPaymentsPage.desktopPrices,
              }}
            />
          </Hidden>

        </Container>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query PaymentsPageQuery {
    datoCmsPaymentsPage {
      title
      payments
      mobilePrices
      desktopPrices
    }
  }
`

export default PricePage
